<template>
    <div>
      <v-breadcrumbs
        large
        :items="crumbs"
        divider="/"
        class="pl-0"
      ></v-breadcrumbs>

      <h1 class="primary--text">
        <v-icon color="primary">mdi-account-multiple</v-icon> <span>Gestão de Tenants</span>
      </h1>
        <div style="position:relative">
        <v-container>
          <v-speed-dial
            absolute
            v-model="speedDial"
            top
            right
            direction="bottom"
            :open-on-hover="true"
          >
            <template v-slot:activator>
              <v-tooltip right color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs" v-on="on"
                    v-model="speedDial"
                    color="primary"
                    dark
                    x-large
                    fab
                  >
                    <v-icon v-if="speedDial">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <span>Opções</span>
              </v-tooltip>
            </template>
            <v-tooltip right color="info">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="info"
                  large
                  @click="searching = true"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <span>Pesquisar</span>
            </v-tooltip>
            <v-tooltip right color="secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs" v-on="on"
                  fab
                  dark
                  small
                  color="secondary"
                  large
                  to="/tenants/create"
                  v-if="$root.session.hasPermission(['super', 'tenants.create'])"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Registar</span>
            </v-tooltip>
          </v-speed-dial>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="total"
          :loading="loading"
          :footer-props="tableFooter"
          :options.sync="options"
          class="mt-16 elevation-10"
          locale="pt-pt"
        >
          <template v-slot:item.status="{item}">
            <status-indicator :status="item.is_active ? 'positive' : 'negative'" />
          </template>

          <template v-slot:item.subscription_end="{item}">
            {{ item.subscription_end }}
            <v-icon color="error" v-if="nearExpiration(item.subscription_end)">
              mdi-bell-alert
            </v-icon>
          </template>

          <template v-slot:item.actions="{item}">
            <IconView
              class="mr-2"
              @click="viewTenant(item)"
            />
            <!--
              v-if="$root.session.hasPermission(['super', 'tenants.delete'])"
            -->
            <IconRemove
              class="mr-2"
              @on-submit="confirmeDeleteTenant(item)"
              :deleteMessage="item.deleted ? 'Ao prosseguir, um email será enviado com um código para a sua conta! Tem a certeza que pretende remover o Tenant?' : 'O Tenant será desativado mas não apagado permanentemente. Só após a desativação é que poderá apagar. Um email irá ser enviado para a sua conta. Pretende prosseguir?'"
            />

            <IconReactivateTenant
              class="mr-2"
              v-if="item.deleted"
              @on-submit="confirmReactivateTenant(item)"
              :deleteMessage="'Irá reactivar o tenant. Tem a certeza que deseja prosseguir? A reactivação não o liga, isso terá de o fazer manualmente'"
            />
          </template>
        </v-data-table>
      </div>
      <v-navigation-drawer
          fixed
          right
          v-model="searching"
          hide-overlay
          width="520"
          class="pt-6"
        >
        <v-list>
          <v-list-item>
            <v-btn fab small color="secondary" @click="searching = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>

            <v-btn absolute right small color="error" @click="resetTenant">
              Limpar Campos
            </v-btn>
          </v-list-item>
        </v-list>
        <v-list class="pt-10">
          <v-form @submit.prevent="searchTenant">
            
            <v-list-item>
              <v-text-field
                v-model="filter.name"
                prepend-inner-icon="mdi-account-details"
                label="Código"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-text-field
                v-model="filter.display_name"
                prepend-inner-icon="mdi-account-details"
                label="Nome"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item>
              <v-checkbox
                v-model="filter.deleted"
                prepend-inner-icon="mdi-deleted"
                label="Eliminados"
                clearable
              ></v-checkbox>
            </v-list-item>
            <v-row
              class="mt-10"
              align="center"
              justify="space-around"
            >
            <v-btn
              color="primary"
              type="submit"
            >
              Pesquisar
            </v-btn>
            </v-row>
          </v-form>
        </v-list>
      </v-navigation-drawer>
      <v-dialog
        width="50%"
        v-model="remove.remove_be_sure"
        v-if="remove.remove_be_sure"
      >
        <v-card>
          <v-card-title class="primary white--text">
            Código segurança
          </v-card-title>
          <v-card-text>
            <v-row class="mt-4">
              <v-col cols="12">
                Insira o código que recebeu no seu email. Confirme se pretende mesmo apagar este Tenant!
              </v-col>
              <v-col cols="12" v-if="false">
                <v-text-field
                  dense outlined
                  disabled
                  v-model="remove.code"
                />
              </v-col>
              <v-col cols="10">
                <v-otp-input
                  v-model="remove.checker"
                  length="6"
                ></v-otp-input>
              </v-col>
              <v-col cols="2">
                <v-btn @click="confirmeDeleteTenant(remove.tenant); remove.checker = null;">
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" align="end">
                <v-btn class="error" :disabled="remove.code != remove.checker" @click="remove.tenant.deleted ? deleteTenant(remove.tenant) : deactivateTenant(remove.tenant)">Remover</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
</template>
<script>
import IconRemove from '@/components/ui/IconRemove.vue';
import IconView from '@/components/ui/IconView.vue';
import IconReactivateTenant from '@/components/ui/IconReactivateTenant.vue';
import Tenant from "@/api/Tenant.js";

export default {
  components: {
    IconRemove,
    IconView,
    IconReactivateTenant
  },
  watch: {
    'options': {
      immediate: false,
      handler () {
        setTimeout(() => {this.searchTenant()}, 10);
      },
      deep: true,
    },
  },
  beforeCreate(){
    document.title = "Ignoto - Gestão de Tenants";

  },
  data: () => ({
    remove:{
      remove_be_sure: false,
      code: null,
      checker: null,
      tenant: null
    },
    headers: [
      {
        text: '',
        sortable: false,
        value: 'status',
      },
      {
        text: 'Ref.',
        sortable: false,
        value: 'reference',
      },
      {
        text: 'Nome',
        sortable: false,
        value: 'name',
      },
      {
        text: 'NIF',
        sortable: false,
        value: 'nif',
      },
      {
        text: 'Telemóvel',
        sortable: false,
        value: 'telephone',
      },
      {
        text: 'Domínio',
        sortable: false,
        value: 'domain',
      },
      { 
        text: 'Opções', 
        value: 'actions', 
        sortable: false 
      },
    ],
    items: [],
    total: 0,
    loading: false,
    searching: false,
    tableFooter: {
      'items-per-page-options': [5, 10, 15, 20],
      'items-per-page-text': "Linhas por página"
    },
    options: {},
    speedDial:false,
    filter: {
      name: null,
      display_name:null,
      user_id:null,
      deleted: false
    },
    users:[]
  }),
  mounted(){

    if(!this.$root.session.hasPermission(["super", "tenants.read"])) {
      this.$router.push('/admin');
    }

    this.fillBaseData();
  },
  methods:{
    makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    },
    confirmeDeleteTenant(item){
      this.remove.tenant = item
      this.remove.code = this.makeid(6)

      console.log(this.remove)

      Tenant.sendDeleteCode(this.remove.code).then(() =>  {
      
      })
      
      this.remove.remove_be_sure = true
    },
    confirmReactivateTenant(item){

      Tenant.reactivate(item.id).then(() =>  {
        this.searchTenant()
      })
      
    },
    nearExpiration(dt){
      const date1 = new Date(dt);
      const date2 = new Date()
      const diffTime = Math.abs(date2 - date1)

      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 

      if(diffDays <= 30)
        return true

      return false;
    },
    fillBaseData() {

      if(localStorage["tenants-" + this.$root.session.id] != undefined){

        let data = JSON.parse(localStorage["tenants-" + this.$root.session.id]);

        this.filter = data.filter;

        this.options = data.options;
      }
    },
    searchTenant(event){
      if(!!event && event.type == 'submit'){
        this.options.page = 1;
      }

      this.loading = true;

      let filter = {...this.filter};

      let request = {
        page: this.options.page,
        limit: this.options.itemsPerPage,
        deleted: this.filter.deleted
      };
    
      Object.assign(request, filter);

      localStorage["tenants-" + this.$root.session.id] = JSON.stringify({
        filter: this.filter,
        options: this.options,
      });

      Tenant.search(request).then(response => {
        
        let data = response.data;

        this.total = data.total;

        this.items = data.data.map((item) => {

          this.searching = false;

          return item;
        });

        this.loading = false;

      });
    },
    resetTenant()
    {
      this.filter = {};

      this.searchTenant();
    },
    viewTenant(item){
      this.$router.push('tenants/update/' + item.id);
    },
    deleteTenant(item){
      Tenant.delete(item.id)
        .then(() => {
          this.remove = {
            remove_be_sure: false,
            code: null,
            checker: null,
            tenant: null
          }
          this.searchTenant();
        });
    },
    deactivateTenant(item){
      Tenant.deactivate(item.id)
        .then(() => {
          this.remove = {
            remove_be_sure: false,
            code: null,
            checker: null,
            tenant: null
          }
          this.searchTenant();
        });
    },
  },
  computed: {
    crumbs: function() {
      return [
        {
          text: 'Gestão de tenants',
          disabled: false,
          to: '/tenants',
          exact: true,
        },
      ];
    }
  }
};
</script>
